import * as React from 'react';

import { Menu, MenuItem, ListItem } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

const languages = {
  en: { nativeName: 'English', short: 'EN' },
  de: { nativeName: 'Deutsch', short: 'DE' }
};

// {Object.keys(languages).map((lng) => (
//     <Button
//       size="xs"
//       key={lng}
//       style={{
//         fontWeight:
//           i18n.resolvedLanguage === lng ? 'bold' : 'normal'
//       }}
//       type="submit"
//       onClick={() => i18n.changeLanguage(lng)}>
//       {languages[lng].short}
//     </Button>
//   ))}

export default function LanguageSwitchListItem() {
  const { t, i18n } = useTranslation();

  const [contextMenu, setContextMenu] = React.useState(null);

  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, languageCode) => {
    event.preventDefault();
    i18n.changeLanguage(languageCode);
    setContextMenu(null);
  };

  const handleToggle = (event) => {
    event.preventDefault();

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = (event) => {
    event.preventDefault();
    setContextMenu(null);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleToggle} ref={anchorRef}>
        {t('common.language')}:&nbsp;{languages[i18n.resolvedLanguage]?.short}
      </ListItem>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }>
        {i18n.languages.map((option, index) => {
          // console.log('Language Option:' + option);
          return (
            <MenuItem
              key={option}
              selected={index === i18n.resolvedLanguage}
              onClick={(event) => handleMenuItemClick(event, option)}
              onClose={handleClose}>
              {languages[option]?.short}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}
