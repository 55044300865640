import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import reducers from '../reducers';

import thunk from 'redux-thunk';
const initialState = {};

const middleware = [thunk];

/* const configureStore = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ),

  {}
);

export default configureStore; */

/* export default function configureStore() {
  return createStore(
    reducers,
    initialState,
    compose(
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    ),

    {}
  );
} */

/* const allReducers = combineReducers(reducers);

export default function configureStore() {
  return createStore(
    combineReducers(
      allReducers,
      initialState,
      applyMiddleware(...middleware),
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
} */

// ORIGINAL von Design-Vorlage
// export default function configureStore() {
//   return createStore(
//     combineReducers({
//       ...reducers
//     }),
//     initialState,
//     compose(
//       applyMiddleware(...middleware),
//       window.__REDUX_DEVTOOLS_EXTENSION__ &&
//         window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
//   );
// }

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    initialState,
    //   compose( // deactivated to to incompatibility with cypress.io
    //     applyMiddleware(...middleware),
    //     window.navigator.userAgent.includes('Chrome')
    //       ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    //           window.__REDUX_DEVTOOLS_EXTENSION__()
    //       : compose
    //   )
    // )

    compose(applyMiddleware(...middleware), compose)
  );
}

// typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
// ? a => a
// : window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__()
