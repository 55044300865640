import React, { Fragment, useState, useContext, useEffect } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  IconButton,
  Box,
  Typography,
  Menu,
  Button,
  List,
  ListItem
} from '@material-ui/core';

import { useSnackbar } from 'notistack';
import Loader from '../../services/loadSpinner';

//import UserService from '../../services/userService';
import { AccountContext } from '../../services/cognito/components/Accounts';

import { useLazyQuery } from '@apollo/client';
import { CLIENTS_LOAD_ALL } from '../../GraphQL/clients/queries';
import { USER_LOAD_BY_ID } from '../../GraphQL/users/queries';

import ReactFetchImage from 'react-fetch-image';

const SidebarUserbox = (props) => {
  const { getSession } = useContext(AccountContext);

  const [userId, setUserId] = useState('');
  const [clientId, setClientId] = useState('');
  // const [givenName, setGivenName] = useState('');
  // const [familyName, setFamilyName] = useState('');

  const [jwt, setJwt] = useState();

  useEffect(() => {
    getSession()
      .then((data) => {
        setJwt(data.idToken.jwtToken);
        // setGivenName(data['custom:givenName']);
        // setFamilyName(data['custom:familyName']);
        setUserId(data['custom:userId']);
        setClientId(data['custom:clientId']);
        getClient();
      })
      .catch((error) => {
        console.log('WARNING: User not logged in.');
      });
  }, []);

  const { sidebarToggle, sidebarHover } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [
    getClient,
    { loading: clientsLoading, error: clientsError, data: clients }
  ] = useLazyQuery(CLIENTS_LOAD_ALL, {
    // variables: { clientId: clientId }
  });

  // const {
  //   loading: usersLoading,
  //   error: usersError,
  //   data: users
  // } = useQuery(USER_LOAD_BY_ID, {
  //   variables: { id: userId }
  // });

  const onApolloError = (error) => {
    enqueueSnackbar('Encountered a problem while loading data.', {
      variant: 'warning'
    });
    console.error(error);
  };

  if (clientsError) onApolloError(clientsError);

  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  if (clients === undefined || clientsLoading) {
    return (
      <Loader
        className="d-flex align-items-center flex-column px-4"
        color={'#5383ff'}
      />
    );
  }

  // const user = users.user;
  const client = clients.clients[0];

  return (
    <Fragment>
      <Box className="app-sidebar-userbox-name my-2" sx={{ maxHeight: 100 }}>
        <Box>
          <div className={'d-flex justify-content-center'}>
            {client?.logo && (
              <ReactFetchImage
                fetcher={{
                  url: client.logo,
                  settings: {
                    headers: {
                      authorization: `Bearer ${jwt}`
                    }
                  }
                }}
              />
            )}

            {/* <img src={client?.logo} /> */}
          </div>
        </Box>
        {/* <Avatar
            alt={`${user?.firstName} ${user?.lastName}`}
            src={user?.avatar}
            className="app-sidebar-userbox-avatar"
          />
          <Box className="app-sidebar-userbox-name">
            <Box>
              <div>
                <b>{`${user?.firstName} ${user?.lastName}`}</b>
              </div>
            </Box>
          </Box> */}
        {/* <Box className="app-sidebar-userbox-description">
            Senior Web Developer
          </Box>
          <Box className="app-sidebar-userbox-btn-profile">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className="text-white"
              href="/PagesProfile">
              View profile
            </Button>
        </Box> */}
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

//export default connect(mapStateToProps)(SidebarUserbox);
export default SidebarUserbox;
