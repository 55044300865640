import React, { Component, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store/configureStore';

import { Provider as ReduxProvider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';

import { SnackbarProvider } from 'notistack';

// Used to create context provider for AWS Cognito Authentification
import { Account as AwsCognitoAccountProvider } from './services/cognito/components/Accounts';
import Pool from './services/cognito/UserPool';

import { UserDataContextProvider } from './context-provider/userDataContextProvider';

// Apollo
import {
  ApolloProvider,
  ApolloClient,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
// Realm
import * as Realm from 'realm-web';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
} from '@fortawesome/free-brands-svg-icons';
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
} from '@fortawesome/free-regular-svg-icons';
import {
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);

// ########################################################################
// Connection to MONGO ATLAS DB via GraphQL / Apollo
// ########################################################################

const APP_ID = process.env.REACT_APP_REALM_APP_ID;

// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);

// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token

  // !ATTENTION, when creating a new REALM instance and / or Cognito User Pools, also change AUD requirement in JWT Auth configuration to new / prod cognito pool id's. Otherwise JWT login to REALM will fail.

  // if (!app.currentUser) {
  const getIdToken = async () =>
    new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(session.idToken.jwtToken);
          }
        });
      }
    });

  const logInToRealm = async () => {
    // console.log('App.js: Realm: Logging in User');
    const idToken = await getIdToken().catch((error) =>
      console.error('ERROR getting Access Token from AWS Cognito:\n', error)
    );

    // console.log('Fetched ID-Token:', idToken);
    //console.log('App.js: Realm: User logged in:', app.currentUser);

    if (idToken) {
      await app.logIn(Realm.Credentials.jwt(idToken));
      // console.log(
      //   'REALM: Logged into Mongo Realm with User ID Token from Cognito.',
      //   idToken
      // );
    } else {
      console.log("No ID-Token available, can't log in to REALM.");
    }
  };

  // if (app.currentUser)
  //   console.log(
  //     'App.js: Realm: Already logged in, current user:',
  //     app.currentUser.isLoggedIn
  //   );

  if (app.currentUser) {
    // An already logged in user's access token might be stale. To guarantee that the token is
    // valid, we refresh the user's custom data which also refreshes their access token.
    // console.log('App.js: Realm: User logged in:', app.currentUser);
    // console.log('Using existing REALM login');
    await app.currentUser.refreshCustomData().catch(async (err) => {
      await logInToRealm();
    });
  } else {
    await logInToRealm();
  }

  return app.currentUser.accessToken;
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const newAuthenticatedApolloClient = () => {
  return new ApolloClient({
    link: new HttpLink({
      uri: `https://eu-central-1.aws.realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
      // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
      // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
      // access token before sending the request.
      fetch: async (uri, options) => {
        const accessToken = await getValidAccessToken();
        options.headers.Authorization = `Bearer ${accessToken}`;
        return fetch(uri, options);
      }
    }),
    cache: new InMemoryCache()
  });
};

const client = newAuthenticatedApolloClient();

const store = configureStore();

class App extends Component {
  render() {
    return (
      // <div id="root">
      <AwsCognitoAccountProvider>
        {/* <AuthAxios> */}
        <ReduxProvider store={store}>
          <ApolloProvider
            client={client}
            authenticateApolloClient={newAuthenticatedApolloClient}>
            <UserDataContextProvider>
              <BrowserRouter basename="/">
                <CssBaseline />
                <ScrollToTop>
                  <SnackbarProvider
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}>
                    <Routes />
                  </SnackbarProvider>
                </ScrollToTop>
              </BrowserRouter>
            </UserDataContextProvider>
          </ApolloProvider>
        </ReduxProvider>
        {/* </AuthAxios> */}
      </AwsCognitoAccountProvider>
      // </div>
    );
  }
}

export default App;
