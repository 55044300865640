import React, { Component, Fragment } from 'react'

// import { ClimbingBoxLoader } from 'react-spinners';
import { PulseLoader } from 'react-spinners'

export default class Loader extends Component {
  render () {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PulseLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the data
          </div>
        </div>
      </Fragment>
    )
  }
}
