import { gql } from '@apollo/client';

export const USERS_LOAD_ALL = gql`
  query UsersLoadAll {
    users {
      _id

      avatar
      email

      lastName
      firstName
      salt

      userPublicKey
      encUserPrivateKey

      associatedClients {
        clientId

        encryptedPrivateKey

        notificationOnNewRequests
        notificationOnAssignedRequests
        notificationOnDeadlineWarningForAllRequests
        notificationOnDeadlineWarningForAssignedRequests
        userIsActive
        userRole
        status
        invitingOrganizationName
      }
      creatingClientId

      log {
        _id
        status
        timestamp
      }
      snsSubscriptions {
        topicARN
        subscriptionARN
      }
      customizationId
    }
  }
`;

export const USER_LOAD_BY_ID = gql`
  query activeUser($id: ObjectId!) {
    user(query: { _id: $id }) {
      _id

      avatar
      email

      lastName
      firstName
      salt

      userPublicKey
      encUserPrivateKey

      creatingClientId

      associatedClients {
        clientId

        encryptedPrivateKey

        notificationOnNewRequests
        notificationOnAssignedRequests
        notificationOnDeadlineWarningForAllRequests
        notificationOnDeadlineWarningForAssignedRequests
        userIsActive
        userRole
        status
        invitingOrganizationName
      }

      log {
        _id
        status
        timestamp
      }
      snsSubscriptions {
        topicARN
        subscriptionARN
      }
      customizationId
    }
  }
`;

export const USERS_LOAD_BY_CLIENTID = gql`
  query UsersLoadByClientId($clientId: [String!]) {
    users(query: { associatedClients: { clientId_in: $clientId } }) {
      _id

      avatar
      email

      creatingClientId

      lastName
      firstName
      salt

      userPublicKey
      encUserPrivateKey

      associatedClients {
        clientId

        encryptedPrivateKey
        notificationOnNewRequests
        notificationOnAssignedRequests
        notificationOnDeadlineWarningForAllRequests
        notificationOnDeadlineWarningForAssignedRequests
        userIsActive
        userRole
        status
        invitingOrganizationName
      }

      log {
        _id
        status
        timestamp
      }
      snsSubscriptions {
        topicARN
        subscriptionARN
      }
      customizationId
    }
  }
`;
