import React from 'react';

export const UserDataContext = React.createContext({
  loggedIn: false,
  userName: '',
  userId: '',
  clientId: '',
  passwordHash: '',
  userGivenName: '',
  userFamilyName: '',
  userGroups: [],
  userRole: '',
  userAvatar: ''
});

export const UserDataContextProvider = (props) => {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userId, setUserId] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [userGroups, setUserGroups] = React.useState([]);
  const [passwordHash, setPasswordHash] = React.useState('');
  const [userFamilyName, setUserFamilyName] = React.useState('');
  const [userGivenName, setUserGivenName] = React.useState('');
  const [userRole, setUserRole] = React.useState('');
  // const [userAvatar, setUserAvatar] = React.useState('');

  const [headerBackgroundColor, setHeaderBackgroundColor] = React.useState('');
  const [headerTextColor, setHeaderTextColor] = React.useState('');
  const [headerLogoUrl, setHeaderLogoUrl] = React.useState('');
  const [headerName, setHeaderName] = React.useState('');
  const [primaryColor, setPrimaryColor] = React.useState('');
  const [secondaryColor, setSecondaryColor] = React.useState('');

  return (
    <UserDataContext.Provider
      value={{
        loggedIn,
        userName,
        passwordHash,
        setLoggedIn,
        setUserName,
        setPasswordHash,
        userFamilyName,
        setUserFamilyName,
        userGivenName,
        setUserGivenName,
        userGroups,
        setUserGroups,
        clientId,
        setClientId,
        userId,
        setUserId,
        userRole,
        setUserRole,
        // userAvatar,
        // getUserAvatar
        headerBackgroundColor,
        setHeaderBackgroundColor,
        headerTextColor,
        setHeaderTextColor,
        headerName,
        setHeaderName,
        primaryColor,
        setPrimaryColor,
        secondaryColor,
        setSecondaryColor,
        headerLogoUrl,
        setHeaderLogoUrl
      }}>
      {props.children}
    </UserDataContext.Provider>
  );
};
