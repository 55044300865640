// import React, { useContext } from 'react';

// import { AccountContext } from './cognito/components/Accounts';
// import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from './cognito/UserPool';
// import {
//   CognitoUserAttribute,
//   CognitoUserToken
// } from 'amazon-cognito-identity-js';

// const userFamilyName = '';
// const userGivenName = '';

export const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject();
        } else {
          const attributes = await new Promise((resolve, reject) => {
            user.getUserAttributes((err, attributes) => {
              if (err) {
                reject(err);
              } else {
                const results = {};

                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }

                resolve(results);
              }
            });
          });

          resolve({
            user,
            ...session,
            ...attributes
          });
        }
      });
    } else {
      reject();
    }
  });

export const getJWToken = () => 'Hallo';

// check roles from Cognito directly
export const userHasRoleSafe = async (role) =>
  await new Promise((resolve, reject) => {
    // session.getIdToken().payload['cognito:groups']
    getSession()
      .then((sessionData) => {
        try {
          const userGroups = sessionData.idToken.payload['cognito:groups'];
          const clientId = sessionData.idToken.payload['custom:clientId'];
          const clientIdArray = clientId?.split(',');
          console.log('Role Checking:', userGroups);
          // prevent error when no roles at all are set for user

          if (userGroups && clientIdArray) {
            clientIdArray.map((id) => {
              if (userGroups.includes(id + '-' + role)) {
                // if role was found, resolve true
                resolve(true);
              }
            });
            // if role was not found, return false
            resolve(false);

            // console.log(
            //   'User has role',
            //   role,
            //   userGroups.join(',').includes(role)
            // );
            // resolve(userGroups.join(',').includes(role));
          } else {
            console.log('User has no roles assigned.');
            resolve(false);
          }
        } catch (err) {
          //reject(err);
          resolve(false);
        }
      })
      .catch((err) => {
        //reject(err);
        resolve(false);
      });
  });

// check roles from stored cookie
export function userHasRoleQuick(role) {
  const userGroups = window.sessionStorage.getItem('userGroups');

  const strippedUserRoles = userGroups.split(',').map((role) => role.slice(25));

  if (userGroups) {
    return strippedUserRoles.includes(role);
  } else {
    return userHasRoleSafe(role);
    // return false;
  }

  // if (userGroups) {
  //   return userGroups.includes(role);
  // } else {
  //   return userHasRoleSafe(role);
  //   // return false;
  // }
}

const getUserData = async () => {
  try {
    const sessionData = await getSession();
    // console.log('sessionData', sessionData);
    // console.log('ClientId:', sessionData['custom:clientId']);
    return { clientId: sessionData['custom:clientId'] };
    // prevent error when no roles at all are set for user
  } catch (err) {
    //reject(err);
    console.error(err);
    return {};
  }
};

const userId = () => window.sessionStorage.getItem('userId');
const clientId = () => window.sessionStorage.getItem('clientId');
const userGivenName = () => window.sessionStorage.getItem('userFamilyName');
const userFamilyName = () => window.sessionStorage.getItem('userGivenName');
const getUserRealName = () => userGivenName() + ' ' + userFamilyName();

const CognitoUserData = {
  userHasRoleQuick,
  userHasRoleSafe,
  userGivenName,
  userFamilyName,
  getUserRealName,
  userId,
  clientId,
  getUserData
};
export default CognitoUserData;
