import React, { Fragment, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Hidden,
  IconButton,
  Box,
  Typography,
  Tooltip
} from '@material-ui/core';

import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';

import PollTwoToneIcon from '@material-ui/icons/PollTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import AlarmClockPlus from '@material-ui/icons/AddAlarmRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoOutlined';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AccountContext } from 'services/cognito/components/Accounts';
import { useLazyQuery } from '@apollo/client';
import { EMAILS_LOAD_ALL } from 'GraphQL/emails/queries';
import { REQUESTS_LOAD_BY_ASSIGNEEID } from 'GraphQL/requests/queries';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function HeaderDots() {
  const { t } = useTranslation();

  const headerTextColor = window.sessionStorage.getItem('headerTextColor');

  const { getSession } = useContext(AccountContext);
  let history = useHistory();

  const [userId, setUserId] = useState();
  const [userGroups, setUserGroups] = useState();
  const [croppedUserGroups, setCroppedUserGroups] = useState();
  const [clientId, setClientId] = useState();

  const [
    fetchEmails,
    { loading: emailsLoading, error: emailsError, data: emailsData }
  ] = useLazyQuery(EMAILS_LOAD_ALL, {
    pollInterval: 300000
  });

  const [
    fetchAssignedRequests,
    {
      loading: assignedRequestsLoading,
      error: assignedRequestsError,
      data: assignedRequestsData
    }
  ] = useLazyQuery(REQUESTS_LOAD_BY_ASSIGNEEID, {
    variables: {
      userId: [userId]
    },
    pollInterval: 300000
  });

  useEffect(() => {
    getSession().then((data) => {
      setClientId(data['custom:clientId']);
      setUserId(data['custom:userId']);
      setUserGroups(data.idToken.payload['cognito:groups']);
      setCroppedUserGroups(
        data.idToken.payload['cognito:groups']?.map(
          (group) => group.split('-')[1]
        )
      );
      fetchEmails();
      fetchAssignedRequests();
    });
  }, []);

  // // if (emailsData)
  // console.log('Emails ', emailsData);
  // // if (assignedRequestsData)
  // console.log('Assigned Requests ', assignedRequestsData);

  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
        <Hidden smDown>
          <Box component="span">
            {/* <Tooltip title={t('header.notifications')}>
              <IconButton
                // onClick={() => {
                //   history.push('/emails');
                // }}
                color="inherit"
                className="btn-inverse mx-0 d-50">
                {emailsData?.emails && emailsData?.emails?.length > 0 && (
                  <div className="badge badge-pill badge-warning badge-header">
                    <small>
                      <b>{emailsData.emails.length}</b>
                    </small>
                  </div>
                )}

                <InfoRoundedIcon fontSize="medium" />
              </IconButton>
            </Tooltip> */}
            {croppedUserGroups?.includes('email') && (
              <Tooltip title={t('header.incoming_emails')}>
                <IconButton
                  onClick={() => {
                    history.push('/emails');
                  }}
                  color="inherit"
                  className="btn-inverse mx-0 d-50">
                  {emailsData?.emails && emailsData?.emails?.length > 0 && (
                    <div className="badge badge-pill badge-warning badge-header">
                      <small>
                        <b>{emailsData.emails.length}</b>
                      </small>
                    </div>
                  )}

                  <AlternateEmailIcon
                    fontSize="medium"
                    style={{ color: headerTextColor }}
                  />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title={t('header.assigned_requests')}>
              <IconButton
                onClick={() => {
                  history.push('/requests');
                }}
                color="inherit"
                className="btn-inverse mx-0 d-50">
                {assignedRequestsData?.requests &&
                  assignedRequestsData.requests?.length > 0 && (
                    <div className="badge badge-pill badge-warning badge-header">
                      <small>
                        <b>
                          {
                            assignedRequestsData.requests.filter(
                              (r) => r.processingStatus < 700
                            ).length
                          }
                        </b>
                      </small>
                    </div>
                  )}
                <AssignmentIcon
                  fontSize="medium"
                  style={{ color: headerTextColor }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Hidden>
      </div>
    </Fragment>
  );
}
