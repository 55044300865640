import { gql } from '@apollo/client';

export const EMAILS_LOAD_ALL = gql`
  query EmailLoadAll {
    emails {
      _id
      date
      hash
      messageId
      clientId
      dataEncrypted
    }
  }
`;

export const EMAIL_LOAD_BY_ID = gql`
  query EmailLoadById($id: ObjectId!) {
    email(query: { _id: $id }) {
      _id
      date
      hash
      messageId
      clientId
      dataEncrypted
    }
  }
`;

export const EMAILS_LOAD_BY_CLIENTID = gql`
  query emails($clientId: String!) {
    emails(query: { clientId: $clientId }) {
      _id
      date
      hash
      messageId
      clientId
      dataEncrypted
    }
  }
`;
