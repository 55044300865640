import { gql } from '@apollo/client';

export const CLIENTS_LOAD_ALL = gql`
  query ClientsLoadAll {
    clients(sortBy: CONTROLLERLEGALNAME_ASC) {
      _id
      autoAssignCaseHandler
      workflowType
      apiKeySecret
      apiKeyId
      publicKey
      businessContactGender
      businessContactMail
      businessContactName
      businessContactPhone
      controllerAddress1
      controllerAddress2
      controllerCity
      controllerContactMail
      controllerContactPhone
      controllerCountry
      controllerDpAuthorityMail
      controllerDpAuthorityName
      controllerLegalName
      controllerLegalRepresentativeNames
      controllerPrivacyOfficerMail
      controllerPrivacyOfficerName
      controllerState
      controllerZip
      products {
        _id
        automatedDecisionMaking
        automatedDecisionMakingDescription
        infoReqStandardText
        name
        procPurposeDescription
        procPurposeHeadline
        type
        selectedByDefault
      }
      datapoints {
        _id
        customRetentionPeriod
        customRetentionPeriodUnit
        dataCategory
        dataCategoryCustom
        dataOrigin
        dataRecipientExternal {
          _id
          recipientId
        }
        dataRecipientInternal
        description
        lawfulness
        legalJustificationForCustomRetention
        name
        products {
          _id
          productId
        }
        retentionWithContract
        transferToNonEU
      }
      externalDataRecipients {
        _id
        address1
        address2
        city
        contractualCommonlyResponsible
        contractualOrderProcessing
        country
        legalEntityIdentifier
        linkToPrivacyStatement
        name
        outsideEUProcessingSafeguards
        processorLocatedInsideEU
        processorHasParentOutsideEU

        processingTakesPlaceInsideEU
        state
        zip
      }
      name
      logo
      origins {
        _id
        name
        apiKeyId
        apiKeySecret
      }

      technicalContactGender
      technicalContactMail
      technicalContactName
      technicalContactPhone

      billingCardHolderName
      billingCreditCardNumber
      billingCcValidMonth
      billingCcValidYear

      smartResponses {
        _id
        title
        languageCode
        text
        validForRequestTypes
        visible
      }
      notificationTopicARN
      preferredLanguage
      identificationParameters {
        _id
        translations {
          title
          languageCode
          description
        }
      }
      maxUsers
      maxOrigins

      stripeCustomerId
      stripeSubscriptions {
        _id
        subscriptionId
        timestamp
      }
      markedForDeletion

      setupWizardRan
      trialEndDate
      dpoAccount

      associatedDpo
    }
  }
`;

export const CLIENT_LOAD_BY_CLIENTID = gql`
  query ClientLoadByClientId($clientId: ObjectId!) {
    client(query: { _id: $clientId }) {
      _id
      autoAssignCaseHandler
      workflowType
      apiKeySecret
      apiKeyId
      publicKey
      businessContactGender
      businessContactMail
      businessContactName
      businessContactPhone
      controllerAddress1
      controllerAddress2
      controllerCity
      controllerContactMail
      controllerContactPhone
      controllerCountry
      controllerDpAuthorityMail
      controllerDpAuthorityName
      controllerLegalName
      controllerLegalRepresentativeNames
      controllerPrivacyOfficerMail
      controllerPrivacyOfficerName
      controllerState
      controllerZip
      products {
        _id
        automatedDecisionMaking
        automatedDecisionMakingDescription
        infoReqStandardText
        name
        procPurposeDescription
        procPurposeHeadline
        type
        selectedByDefault
      }
      datapoints {
        _id
        customRetentionPeriod
        customRetentionPeriodUnit
        dataCategory
        dataCategoryCustom
        dataOrigin
        dataRecipientExternal {
          _id
          recipientId
        }
        dataRecipientInternal
        description
        lawfulness
        legalJustificationForCustomRetention
        name
        products {
          _id
          productId
        }
        retentionWithContract
        transferToNonEU
      }
      externalDataRecipients {
        _id
        address1
        address2
        city
        contractualCommonlyResponsible
        contractualOrderProcessing
        country
        legalEntityIdentifier
        linkToPrivacyStatement
        name
        outsideEUProcessingSafeguards
        processorLocatedInsideEU
        processorHasParentOutsideEU

        processingTakesPlaceInsideEU
        state
        zip
      }
      name
      logo
      origins {
        _id
        name
        apiKeyId
        apiKeySecret
      }

      technicalContactGender
      technicalContactMail
      technicalContactName
      technicalContactPhone

      billingCardHolderName
      billingCreditCardNumber
      billingCcValidMonth
      billingCcValidYear

      smartResponses {
        _id
        title
        languageCode
        text
        validForRequestTypes
        visible
      }
      notificationTopicARN
      preferredLanguage
      identificationParameters {
        _id
        translations {
          title
          languageCode
          description
        }
      }
      maxUsers
      maxOrigins

      stripeCustomerId
      stripeSubscriptions {
        _id
        subscriptionId
        timestamp
      }
      markedForDeletion

      setupWizardRan
      trialEndDate
      dpoAccount

      associatedDpo
    }
  }
`;

export const CLIENTS_LOAD_BY_DPOID = gql`
  query ClientLoadByClientId($dpoId: String!) {
    clients(query: { associatedDpo: $dpoId }) {
      _id
      autoAssignCaseHandler
      workflowType
      apiKeySecret
      apiKeyId
      publicKey
      businessContactGender
      businessContactMail
      businessContactName
      businessContactPhone
      controllerAddress1
      controllerAddress2
      controllerCity
      controllerContactMail
      controllerContactPhone
      controllerCountry
      controllerDpAuthorityMail
      controllerDpAuthorityName
      controllerLegalName
      controllerLegalRepresentativeNames
      controllerPrivacyOfficerMail
      controllerPrivacyOfficerName
      controllerState
      controllerZip
      products {
        _id
        automatedDecisionMaking
        automatedDecisionMakingDescription
        infoReqStandardText
        name
        procPurposeDescription
        procPurposeHeadline
        type
        selectedByDefault
      }
      datapoints {
        _id
        customRetentionPeriod
        customRetentionPeriodUnit
        dataCategory
        dataCategoryCustom
        dataOrigin
        dataRecipientExternal {
          _id
          recipientId
        }
        dataRecipientInternal
        description
        lawfulness
        legalJustificationForCustomRetention
        name
        products {
          _id
          productId
        }
        retentionWithContract
        transferToNonEU
      }
      externalDataRecipients {
        _id
        address1
        address2
        city
        contractualCommonlyResponsible
        contractualOrderProcessing
        country
        legalEntityIdentifier
        linkToPrivacyStatement
        name
        outsideEUProcessingSafeguards
        processorLocatedInsideEU
        processorHasParentOutsideEU

        processingTakesPlaceInsideEU
        state
        zip
      }
      name
      logo
      origins {
        _id
        name
        apiKeyId
        apiKeySecret
      }

      technicalContactGender
      technicalContactMail
      technicalContactName
      technicalContactPhone

      billingCardHolderName
      billingCreditCardNumber
      billingCcValidMonth
      billingCcValidYear

      smartResponses {
        _id
        title
        languageCode
        text
        validForRequestTypes
        visible
      }
      notificationTopicARN
      preferredLanguage
      identificationParameters {
        _id
        translations {
          title
          languageCode
          description
        }
      }
      maxUsers
      maxOrigins

      stripeCustomerId
      stripeSubscriptions {
        _id
        subscriptionId
        timestamp
      }
      markedForDeletion

      setupWizardRan
      trialEndDate
      dpoAccount

      associatedDpo
    }
  }
`;
