import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { userHasRoleSafe, userHasRoleQuick } from '../services/CognitoUserData';

// renders child content if the user has the given role (assigned by group membership in AWS Cognito)
// check Cognito Server for Role
export const RenderOnRole = ({ role, children }) => {
  // checks role from JWT Token with server request. Server denies too many requests, therefore a "quick" version was implemented below to check from static session information
  // yet for security reasons, this function should be implemented to all confidential pages
  const [page, setPage] = useState(
    <div className="">Checking permissions...</div>
  );

  useEffect(() => {
    console.log('Checking permissions...');
    userHasRoleSafe(role)
      .then((result) =>
        result
          ? setPage(children)
          : setPage('You are not autorized to access this page.')
      )
      .catch((err) => {
        console.error(err);
        setPage('An error occured.');
      });
  }, []);

  // userHasRoleSafe(role)
  //   .then((result) =>
  //     result
  //       ? setPage(children)
  //       : setPage('You are not autorized to access this page.')
  //   )
  //   .catch((err) => {
  //     console.error(err);
  //     setPage('An error occured.');
  //   });

  return page;
};

// renders child content if the user has the given role (assigned by group membership in AWS Cognito)
export const RenderOnRoleSilent = ({ role, children }) =>
  userHasRoleQuick(role) && children;

// check session storage, where roles have been stored for quick access (without server request), caution, unsafe as session storage could be altered.
// only used for showing navigation menu items with approp. permissions
export const RenderOnRoleQuick = ({ role, children }) =>
  userHasRoleQuick(role) && children;

RenderOnRole.propTypes = {
  role: PropTypes.string.isRequired
};

RenderOnRoleSilent.propTypes = {
  role: PropTypes.string.isRequired
};

RenderOnRoleQuick.propTypes = {
  role: PropTypes.string.isRequired
};

export default RenderOnRole;
