import i18n from 'i18n';

// export const daysForProcesing = 30;
export const usualDeadlineInDays = 30;
export const prolongationDeadlineInDays = 60;

export const daysLeftUntilWarning = 7;
export const daysTaskDueBeforeRequestDue = 3;

export const identificationMethods = {
  assisted: {
    label: 'pages.create_request.identification_method_assisted',
    description:
      'pages.create_request.identification_method_assisted_description'
  },

  //  'You define the required parameters to identify a requestor. PRIVAPI PORTAL will send an email to the requestor to establish a secure communication channel and asking him to provide the necessary information to complete the identification process.'

  manual: {
    label: 'pages.create_request.identification_method_manual',
    description: 'pages.create_request.identification_method_manual_description'

    //'You identify the requestor without any assistance from PRIVAPI PORTAL. You can, however, provide some information on how you identified the requestor for documentation purposes.'
  }
};

export const units = {
  day: 'units.days_one',
  days: 'units.days_other',
  month: 'units.months_one',
  months: 'units.months_other',
  year: 'units.years_one',
  years: 'units.years_other'
};

export const processingStatus = {
  0: {
    short: 'technical_variables.processing_status.0.short',
    label: 'technical_variables.processing_status.0.label',
    color: 'badge-info'
  },
  50: {
    short: 'technical_variables.processing_status.50.short',
    label: 'technical_variables.processing_status.50.label',
    color: 'badge-neutral-info'
  },
  100: {
    short: 'technical_variables.processing_status.100.short',
    label: 'technical_variables.processing_status.100.label',
    color: 'badge-neutral-info'
  },
  210: {
    short: 'technical_variables.processing_status.210.short',
    label: 'technical_variables.processing_status.210.label',
    color: 'badge-info'
  },
  220: {
    short: 'technical_variables.processing_status.220.short',
    label: 'technical_variables.processing_status.220.label',
    color: 'badge-neutral-primary'
  },
  225: {
    short: 'technical_variables.processing_status.225.short',
    label: 'technical_variables.processing_status.225.label',
    color: 'badge-neutral-second'
  },
  230: {
    short: 'technical_variables.processing_status.230.short',
    label: 'technical_variables.processing_status.230.label',
    color: 'badge-neutral-second'
  },
  250: {
    short: 'technical_variables.processing_status.250.short',
    label: 'technical_variables.processing_status.250.label',
    color: 'badge-info'
  },

  280: {
    short: 'technical_variables.processing_status.280.short',
    label: 'technical_variables.processing_status.280.label',
    color: 'badge-neutral-primary'
  },
  290: {
    short: 'technical_variables.processing_status.290.short',
    label: 'technical_variables.processing_status.290.label',
    color: 'badge-info'
  },

  400: {
    short: 'technical_variables.processing_status.400.short',
    label: 'technical_variables.processing_status.400.label',
    color: 'badge-neutral-success'
  },
  420: {
    short: 'technical_variables.processing_status.420.short',
    label: 'technical_variables.processing_status.420.label',
    color: 'badge-info'
  },
  450: {
    short: 'technical_variables.processing_status.450.short',
    label: 'technical_variables.processing_status.450.label',
    color: 'badge-neutral-primary'
  },
  460: {
    short: 'technical_variables.processing_status.460.short',
    label: 'technical_variables.processing_status.460.label',
    color: 'badge-info'
  },
  500: {
    short: 'technical_variables.processing_status.500.short',
    label: 'technical_variables.processing_status.500.label',
    color: 'badge-info'
  },

  600: {
    short: 'technical_variables.processing_status.600.short',
    label: 'technical_variables.processing_status.600.label',
    color: 'badge-info'
  },

  700: {
    short: 'technical_variables.processing_status.700.short',
    label: 'technical_variables.processing_status.700.label',
    color: 'badge-success'
  },
  800: {
    short: 'technical_variables.processing_status.800.short',
    label: 'technical_variables.processing_status.800.label',
    color: 'badge-success'
  },
  900: {
    short: 'technical_variables.processing_status.900.short',
    label: 'technical_variables.processing_status.900.label',
    color: 'badge-dark'
  }
};

//   i18n.t('technical_variables.processing_status.new_request'), // 0
//   'Case Handler Assigned', // 1
//   'In Progress', // 2
//   'Waiting for Review', // 3
//   'Review Done', // 4
//   'Sent to Requestor', // 5
//   'Acknowledged by Requestor' // 6
// ];

export const processingStatusShort = [
  'technical_variables.processing_status_short.new',
  'technical_variables.processing_status_short.assigned',
  'technical_variables.processing_status_short.in_progress',
  'technical_variables.processing_status_short.waiting_for_review',
  'technical_variables.processing_status_short.reviewed',
  'technical_variables.processing_status_short.sent_out',
  'technical_variables.processing_status_short.acknowledged'
];

export const piiDatapointCategories = [
  {
    id: 1,
    label: 'technical_variables.pii_datapoint_categories.personal_details'
  },
  {
    id: 2,
    label:
      'technical_variables.pii_datapoint_categories.address_and_contact_details'
  },
  {
    id: 3,
    label: 'technical_variables.pii_datapoint_categories.user_credentials'
  },
  {
    id: 4,
    label: 'technical_variables.pii_datapoint_categories.financial_details'
  },
  {
    id: 5,
    label: 'technical_variables.pii_datapoint_categories.employment_details'
  },
  {
    id: 6,
    label: 'technical_variables.pii_datapoint_categories.qualification_details'
  },
  {
    id: 7,
    label: 'technical_variables.pii_datapoint_categories.performance_details'
  },
  {
    id: 8,
    label:
      'technical_variables.pii_datapoint_categories.race_or_ethnical_details'
  },
  {
    id: 9,
    label:
      'technical_variables.pii_datapoint_categories.religious_or_ideological_beliefs'
  },
  {
    id: 10,
    label:
      'technical_variables.pii_datapoint_categories.indictments_and_suspected_offenses'
  },
  {
    id: 11,
    label:
      'technical_variables.pii_datapoint_categories.criminal_offenses_convictions_or_sentences'
  },
  {
    id: 12,
    label:
      'technical_variables.pii_datapoint_categories.physical_or_mental_health_details'
  },

  {
    id: 13,
    label:
      'technical_variables.pii_datapoint_categories.official_documents_passports'
  },

  {
    id: 14,
    label:
      'technical_variables.pii_datapoint_categories.physical_or_mental_health_details'
  },

  {
    id: 15,
    label:
      'technical_variables.pii_datapoint_categories.political_opinions_memberships'
  },

  {
    id: 16,
    label:
      'technical_variables.pii_datapoint_categories.non-political_opinions_memberships'
  },

  {
    id: 17,
    label: 'technical_variables.pii_datapoint_categories.usage_tracking_details'
  },

  {
    id: 18,
    label: 'technical_variables.pii_datapoint_categories.biometrical_data'
  },
  {
    id: 19,
    label: 'technical_variables.pii_datapoint_categories.payment_details'
  },

  {
    id: 99,
    label: 'technical_variables.pii_datapoint_categories.other'
  }
];

export const gdprLawfulnessOfProcessing = [
  {
    id: 'a',
    article: 'technical_variables.gdpr_lawfulness_of_processing.a.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.a.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.a.long'
  },
  {
    id: 'b',
    article: 'technical_variables.gdpr_lawfulness_of_processing.b.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.b.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.b.long'
  },
  {
    id: 'c',
    article: 'technical_variables.gdpr_lawfulness_of_processing.c.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.c.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.c.long'
  },
  {
    id: 'd',
    article: 'technical_variables.gdpr_lawfulness_of_processing.d.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.d.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.d.long'
  },
  {
    id: 'e',
    article: 'technical_variables.gdpr_lawfulness_of_processing.e.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.e.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.e.long'
  },
  {
    id: 'f',
    article: 'technical_variables.gdpr_lawfulness_of_processing.f.article',
    short: 'technical_variables.gdpr_lawfulness_of_processing.f.short',
    long: 'technical_variables.gdpr_lawfulness_of_processing.f.long'
  }
];

export const dataProvidedBy = [
  {
    id: 1,
    short: 'technical_variables.data_provided_by.1.short',
    long: 'technical_variables.data_provided_by.1.long'
  },
  {
    id: 2,
    short: 'technical_variables.data_provided_by.2.short',
    long: 'technical_variables.data_provided_by.2.long'
  },
  {
    id: 3,
    short: 'technical_variables.data_provided_by.3.short',
    long: 'technical_variables.data_provided_by.3.long'
  }
];

export const requestTypes = [
  {
    id: 0,
    label: 'technical_variables.request_types.0.label',
    long: 'technical_variables.request_types.0.long'
  },
  {
    id: 1,
    label: 'technical_variables.request_types.1.label',
    long: 'technical_variables.request_types.1.long'
  },
  {
    id: 2,
    label: 'technical_variables.request_types.2.label',
    long: 'technical_variables.request_types.2.long'
  },
  {
    id: 3,
    label: 'technical_variables.request_types.3.label',
    long: 'technical_variables.request_types.3.long'
  },
  {
    id: 4,
    label: 'technical_variables.request_types.4.label',
    long: 'technical_variables.request_types.4.long'
  },
  {
    id: 5,
    label: 'technical_variables.request_types.5.label',
    long: 'technical_variables.request_types.5.long'
  },
  {
    id: 100,
    label: 'technical_variables.request_types.100.label',
    long: 'technical_variables.request_types.100.long'
  },
  {
    id: 199,
    label: 'technical_variables.request_types.199.label',
    long: 'technical_variables.request_types.199.long'
  }
];

// roles available to assign to users
export const privapiPortalPrivileges = [
  {
    id: 1,
    roleName: 'PortalAdmin',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.1.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.1.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.1.roleDescription',
    roleIdentifier: 'privapi-portal-client-admin',
    roleIdentifier2: 'admin',
    setAsDefault: false
  },
  {
    id: 2,
    roleName: 'PortalUser',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.2.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.2.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.2.roleDescription',
    roleIdentifier: 'privapi-portal-user',
    roleIdentifier2: 'user',
    setAsDefault: true
  },
  {
    id: 3,
    roleName: 'PortalEmailCapture',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.3.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.3.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.3.roleDescription',
    roleIdentifier: 'privapi-portal-email-capture',
    roleIdentifier2: 'email',
    setAsDefault: false
  },
  {
    id: 4,
    roleName: 'PortalReviewer',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.4.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.4.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.4.roleDescription',
    roleIdentifier: 'privapi-portal-reviewer',
    roleIdentifier2: 'reviewer',
    setAsDefault: false
  },
  {
    id: 5,
    roleName: 'PortalSendToUser',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.5.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.5.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.5.roleDescription',
    roleIdentifier: 'privapi-portal-sendToUser',
    roleIdentifier2: 'sender',
    setAsDefault: false
  },
  {
    id: 6,
    roleName: 'PortalDemoUser',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.6.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.6.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.6.roleDescription',
    roleIdentifier: 'privapi-portal-demo',
    roleIdentifier2: 'demo',
    setAsDefault: false
  },
  {
    id: 7,
    roleName: 'PortalIdentification',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.7.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.7.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.7.roleDescription',
    roleIdentifier: 'privapi-portal-identification',
    roleIdentifier2: 'identification',
    setAsDefault: false
  },
  {
    id: 8,
    roleName: 'CreateNewRequest',
    roleLabelShort:
      'technical_variables.privapi_portal_privileges.8.roleLabelShort',
    roleLabelLong:
      'technical_variables.privapi_portal_privileges.8.roleLabelLong',
    roleDescription:
      'technical_variables.privapi_portal_privileges.8.roleDescription',
    roleIdentifier: 'privapi-portal-create-request',
    roleIdentifier2: 'create',
    setAsDefault: false
  }
];

export const userRoleLabelsAndPrivileges = {
  admin: {
    label: 'technical_variables.user_role_labels_and_privileges.admin.label',
    description:
      'technical_variables.user_role_labels_and_privileges.admin.description',
    privileges: {
      PortalAdmin: true,
      PortalUser: true,
      PortalReviewer: true,
      PortalSendToUser: true,
      PortalDemoUser: true,
      PortalEmailCapture: true,
      PortalIdentification: true,
      CreateNewRequest: true
    }
  },
  contributor: {
    label:
      'technical_variables.user_role_labels_and_privileges.contributor.label',
    description:
      'technical_variables.user_role_labels_and_privileges.contributor.description',
    privileges: {
      PortalAdmin: false,
      PortalUser: true,
      PortalReviewer: false,
      PortalSendToUser: false,
      PortalDemoUser: false,
      PortalEmailCapture: false,
      PortalIdentification: false,
      CreateNewRequest: false
    }
  },
  reviewer: {
    label: 'technical_variables.user_role_labels_and_privileges.reviewer.label',
    description:
      'technical_variables.user_role_labels_and_privileges.reviewer.description',
    privileges: {
      PortalAdmin: false,
      PortalUser: true,
      PortalReviewer: true,
      PortalSendToUser: true,
      PortalDemoUser: false,
      PortalEmailCapture: false,
      PortalIdentification: false,
      CreateNewRequest: false
    }
  },
  dpo: {
    label: 'technical_variables.user_role_labels_and_privileges.dpo.label',
    description:
      'technical_variables.user_role_labels_and_privileges.dpo.description',
    privileges: {
      PortalAdmin: false,
      PortalUser: true,
      PortalReviewer: true,
      PortalSendToUser: true,
      PortalDemoUser: false,
      PortalEmailCapture: true,
      PortalIdentification: true,
      CreateNewRequest: true
    }
  }
};

const messageTypes = {
  1: { label: 'response' },
  2: { label: 'initial request' },
  3: { label: 'internal chat' },
  4: { label: 'message exchange with requestor' }
};
