import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { SnackbarProvider } from 'notistack';

// import { ClimbingBoxLoader } from 'react-spinners';
import { PulseLoader } from 'react-spinners';

// import MuiTheme from './theme';

import ThemeWrapper from 'theme/themeContextProvider';

// Layout Blueprints

import {
  LeftSidebar,
  // LeftSidebar,
  // CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

//import HttpService from './services/httpService';

import ForwardToLoginIfNotAuthenticated from './components/ForwardToLoginIfNotAuthenticated';
import { Settings } from 'react-feather';

const SettingsUser = lazy(() => import('./routes/accountSettings'));
const Dashboard = lazy(() => import('./routes/dashboard'));
const Requests = lazy(() => import('./routes/requests'));
const RequestsMyAssignments = lazy(() =>
  import('./routes/requestsMyAssignments')
);

const SettingsDpoInformation = lazy(() =>
  import('./routes/settingsDpoInformation')
);
const SettingsDpoUserManagement = lazy(() =>
  import('./routes/settingsDpoUserManagement')
);

const SettingsDpoClients = lazy(() => import('./routes/settingsDpoClients'));

const SettingsClientInformation = lazy(() =>
  import('./routes/settingsClientInformation')
);
const SettingsClientInputChannels = lazy(() =>
  import('./routes/settingsClientInputChannels')
);
const SettingsClientProducts = lazy(() =>
  import('./routes/settingsClientProducts')
);
const SettingsClientDatapoints = lazy(() =>
  import('./routes/settingsClientDataPoints')
);

const SettingsClientUserManagement = lazy(() =>
  import('./routes/settingsClientUserManagement')
);
const SettingsClientBilling = lazy(() =>
  import('./routes/settingsClientBilling')
);
const SettingsClientWorkflow = lazy(() =>
  import('./routes/settingsClientWorkflow')
);

const SettingsClientEmailEditor = lazy(() =>
  import('./routes/settingsClientEmailEditor')
);

const SettingsApiAccess = lazy(() =>
  import('./routes/settingsClientApiAccess')
);

const Emails = lazy(() => import('./routes/emails'));

const ConsentList = lazy(() => import('./routes/consentList'));

const RequestDetails = lazy(() => import('./routes/requestsDetails'));
const EndpointTest = lazy(() => import('./routes/endpointTest'));
const Login = lazy(() => import('./routes/cognito-login'));
const Logout = lazy(() => import('./routes/cognito-logout'));
const Signup = lazy(() => import('./routes/cognito-signup'));
const ForgotPassword = lazy(() => import('./routes/cognito-forgotPassword'));

const SettingsClientSmartResponses = lazy(() =>
  import('./routes/settingsClientSmartResponses')
);

//HttpService.configure();

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PulseLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Please wait while we load the data
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    // <ThemeProvider theme={MuiTheme}>
    <ThemeWrapper>
      <SnackbarProvider maxSnack={3}>
        <AnimatePresence>
          <Suspense fallback={<SuspenseLoading />}>
            {/* <LoadUserData /> */}
            <Switch>
              <Redirect exact from="/" to="/dashboard" />

              <Route path={['/login']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/login" component={Login} />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>
              <Route path={['/logout']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route path="/logout" component={Logout} />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route>

              {/* <Route path={['/forgotpassword']}>
                <MinimalLayout>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <Route
                        path="/forgotpassword"
                        component={ForgotPassword}
                      />
                    </motion.div>
                  </Switch>
                </MinimalLayout>
              </Route> */}

              {/* The following pages are protected with login*/}

              {/* <ForwardToLoginIfNotAuthenticated> */}
              <Route path={['/dashboard']}>
                {/* <PresentationLayout> */}

                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/dashboard" component={Dashboard} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                  {/* </PresentationLayout> */}
                </LeftSidebar>
              </Route>

              <Route path={['/emails']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/emails" component={Emails} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/requests/myassignments']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/requests/myassignments"
                          component={RequestsMyAssignments}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/requests/details/:id', '/requests/:id']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path={['/requests/details/:id', '/requests/:id']}
                          component={RequestDetails}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/requests/list', '/requests']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path={['/requests/list', '/requests']}
                          component={Requests}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/user']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/settings/user" component={SettingsUser} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/consents/active']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/consents" component={ConsentList} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>
              <Route path={['/consents/archive']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/consents" component={ConsentList} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/general']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/general"
                          component={SettingsClientInformation}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/inputchannels']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/inputchannels"
                          component={SettingsClientInputChannels}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/products']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/products"
                          component={SettingsClientProducts}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/datapoints']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/datapoints"
                          component={SettingsClientDatapoints}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/usermanagement']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/usermanagement"
                          component={SettingsClientUserManagement}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/billing']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/billing"
                          component={SettingsClientBilling}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/workflow']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/workflow"
                          component={SettingsClientWorkflow}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/apiaccess']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/apiaccess"
                          component={SettingsApiAccess}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/smartresponses']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/smartresponses"
                          component={SettingsClientSmartResponses}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/client/emaileditor']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/client/emaileditor"
                          component={SettingsClientEmailEditor}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              {/* limited client settings for DPOs */}
              <Route path={['/settings/dpo/orgdata']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/dpo/orgdata"
                          component={SettingsDpoInformation}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/dpo/user']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/dpo/user"
                          component={SettingsDpoUserManagement}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/settings/dpo/clients']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route
                          path="/settings/dpo/clients"
                          component={SettingsDpoClients}
                        />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>

              <Route path={['/endpoint']}>
                <LeftSidebar>
                  <Switch location={location} key={location.pathname}>
                    <motion.div
                      initial="initial"
                      animate="in"
                      exit="out"
                      variants={pageVariants}
                      transition={pageTransition}>
                      <ForwardToLoginIfNotAuthenticated>
                        <Route path="/endpoint" component={EndpointTest} />
                      </ForwardToLoginIfNotAuthenticated>
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              </Route>
            </Switch>
          </Suspense>
        </AnimatePresence>
      </SnackbarProvider>
      {/*  </ThemeProvider> */}
    </ThemeWrapper>
  );
};

export default Routes;
