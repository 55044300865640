import i18n from 'i18n';

import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SnoozeIcon from '@material-ui/icons/Snooze';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InputIcon from '@material-ui/icons/Input';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import SecurityOutlinedIcon from '@material-ui/icons/SecurityOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import PortraitOutlinedIcon from '@material-ui/icons/PortraitOutlined';

const iconsMap = {
  PlayForWorkIcon: PlayForWorkIcon,
  InputIcon: InputIcon,
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  DoneAllIcon: DoneAllIcon,
  SnoozeIcon: SnoozeIcon,
  CloudDoneIcon: CloudDoneIcon,
  CloudQueueIcon: CloudQueueIcon,
  EqualizerIcon: EqualizerIcon,
  LiveTvIcon: LiveTvIcon,
  MailOutlineIcon: MailOutlineIcon,
  AlternateEmailIcon: AlternateEmailIcon,
  SecurityOutlinedIcon: SecurityOutlinedIcon,
  SupervisedUserCircleOutlinedIcon: SupervisedUserCircleOutlinedIcon,
  PortraitOutlinedIcon: PortraitOutlinedIcon
};

function GetNavMenuItems() {
  //const { t, i18n } = useTranslation();

  const navMenu = [
    {
      label: i18n.t('navigation_menu.dashboard'),
      icon: 'BarChartIcon',
      iconColor: '#f5424b',
      to: '/dashboard'
      //requiredRole: 'user'
    },
    {
      label: i18n.t('navigation_menu.subject_rights_requests'),
      // icon: 'PlayForWorkIcon',
      icon: 'MailOutlineIcon',
      iconColor: '#f5424b',
      to: '/requests',
      requiredRole: 'user'
    },
    {
      label: i18n.t('navigation_menu.incoming_emails'),
      // icon: 'MailOutlineIcon',
      icon: 'AlternateEmailIcon',
      iconColor: '#f5424b',
      to: '/emails',
      requiredRole: 'email'
    },

    {
      label: i18n.t('navigation_menu.my_account'),
      icon: 'PersonIcon',
      // requiredRole: 'user',
      to: '/settings/user'
    },
    {
      label: i18n.t('navigation_menu.client_settings.title'),
      icon: 'SettingsIcon',
      requiredRole: 'admin',
      content: [
        {
          label: i18n.t('navigation_menu.client_settings.organization_setup'),
          to: '/settings/client/general',
          requiredRole: 'admin'
        },
        {
          label: i18n.t('navigation_menu.client_settings.products'),
          to: '/settings/client/products',
          requiredRole: 'admin'
        },
        {
          label: i18n.t('navigation_menu.client_settings.datapoints'),
          to: '/settings/client/datapoints',
          requiredRole: 'admin'
        },
        {
          label: i18n.t('navigation_menu.client_settings.input_channels'),
          to: '/settings/client/inputchannels',
          requiredRole: 'admin'
        },
        {
          label: 'Smart Responses',
          to: '/settings/client/smartresponses',
          requiredRole: 'admin'
        },
        {
          label: i18n.t(
            'navigation_menu.client_settings.workflow_configuration'
          ),
          to: '/settings/client/workflow',
          requiredRole: 'admin'
        },
        {
          label: i18n.t('navigation_menu.client_settings.user_management'),
          to: '/settings/client/usermanagement',
          requiredRole: 'admin'
        },
        {
          label: i18n.t('navigation_menu.client_settings.manage_subscription'),
          to: '/settings/client/billing',
          requiredRole: 'admin'
        }
        // ,{
        //   label: i18n.t('navigation_menu.client_settings.API_access'),
        //   to: '/settings/client/apiaccess',
        //   requiredRole: 'admin'
        // }
      ]
    },
    {
      label: i18n.t('navigation_menu.dpo.title'),
      icon: 'PortraitOutlinedIcon',
      requiredRole: 'dpo-admin',
      content: [
        {
          label: i18n.t('navigation_menu.dpo.organization_setup'),
          to: '/settings/dpo/orgdata',
          requiredRole: 'dpo-admin'
        },
        {
          label: i18n.t('navigation_menu.dpo.user_management'),
          to: '/settings/dpo/user',
          requiredRole: 'dpo-admin'
        },
        {
          label: i18n.t('navigation_menu.dpo.client_management'),
          to: '/settings/dpo/clients',
          requiredRole: 'dpo-admin'
        }
      ]
    }
    // {
    //   label: i18n.t('navigation_menu.request_simulation'),
    //   icon: 'LiveTvIcon',
    //   requiredRole: 'demo',
    //   to: '/endpoint'
    // }
  ];
  if (i18n)
    return [
      {
        label: i18n.t('navigation_menu.menu_title'),
        content: JSON.parse(JSON.stringify(navMenu), (key, value) => {
          if (key === 'icon') {
            return iconsMap[value];
          } else {
            return value;
          }
        })
      }
    ];
}

export default GetNavMenuItems;
