import React, { useState, useContext, useEffect } from 'react';
import { matchPath } from 'react-router-dom';

import PropTypes from 'prop-types';

import { List, Typography } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import SidebarMenuListItem from './SidebarMenuListItem';

import { RenderOnRoleQuick } from '../../components/RenderOnRole';
import { AccountContext } from '../../services/cognito/components/Accounts';

const SidebarMenuList = (props) => {
  const { pages, ...rest } = props;

  return (
    <List className="p-0">
      {pages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = (props) => {
  const { router, items, page, depth } = props;

  if (page.content) {
    const open =
      router &&
      matchPath(router.location.pathname, {
        path: page.to,
        exact: false
      });

    items.push(
      <RenderOnRoleQuick role={page?.requiredRole} key={page.label + '0'}>
        <SidebarMenuListItem
          depth={depth}
          icon={page.icon}
          iconColor={page.iconColor}
          key={page.label}
          label={page.badge}
          open={Boolean(open)}
          title={page.label}>
          <div className="sidebar-menu-children py-2">
            <SidebarMenuList
              depth={depth + 1}
              pages={page.content}
              router={router}
            />
          </div>
        </SidebarMenuListItem>
      </RenderOnRoleQuick>
    );
  } else {
    if (page.requiredRole)
      // if a required role is set for the nav item, only render it if the user has the required role
      items.push(
        <RenderOnRoleQuick role={page.requiredRole} key={page.label + '1'}>
          <SidebarMenuListItem
            depth={depth}
            href={page.to}
            icon={page.icon}
            iconColor={page.iconColor}
            key={page.label}
            label={page.badge}
            title={page.label}
          />
        </RenderOnRoleQuick>
      );
    // if no required role is set, render the nav item without any checks
    else
      items.push(
        <SidebarMenuListItem
          depth={depth}
          href={page.to}
          icon={page.icon}
          iconColor={page.iconColor}
          key={page.label}
          label={page.badge}
          title={page.label}
        />
      );
  }

  return items;
};

const SidebarMenu = (props) => {
  const { title, pages, className, component: Component, ...rest } = props;
  const router = useRouter();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} />
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

SidebarMenu.defaultProps = {
  component: 'nav'
};

export default SidebarMenu;
