import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AccountContext } from '../../services/cognito/components/Accounts';

import axios from 'axios';

import { Avatar, Tooltip } from '@material-ui/core';

export default function AvatarFromApiBinary({
  src,
  alt,
  children,
  idToken = null,
  sx = {},
  style = {}
}) {
  const [jwt, setJwt] = useState(idToken);
  const [avatarBinary, setAvatarBinary] = useState(null);

  const { getSession } = useContext(AccountContext);

  const fetchAvatar = async (idToken) =>
    new Promise((resolve, reject) => {
      if (src) {
        // console.log('Fetching avatar from API:', src);
        axios
          .get(src, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
          .then((response) => {
            // console.log('Binary picture Data from Avatar:', response.data);
            resolve(response.data);
          })
          .catch((error) => {
            console.error('Error fetching User Avatar:', error);
            reject(error);
          });
      }
    });

  useEffect(() => {
    try {
      // console.log('Fetching image...');
      if (!jwt) {
        // console.log('Receiving token first....');
        getSession()
          .then((data) => {
            // console.log('Token received....', data.idToken.jwtToken);
            setJwt(data.idToken.jwtToken);
            fetchAvatar(data.idToken.jwtToken).then((data) => {
              setAvatarBinary(data);
            });
          })
          .catch((error) => {
            console.error(error);
            // console.log('WARNING: User not logged in.');
          });
      } else {
        // console.log('Token already in memory....', jwt);
        fetchAvatar(jwt).then((data) => {
          setAvatarBinary(data);
        });
      }
    } catch (error) {
      console.log('User Avatar could not be fetched from API', error);
    }
  }, [src]);

  // console.log('Avatar Binary for ' + alt + '??', avatarBinary !== undefined);

  // if (avatarBinary) {
  return (
    <Tooltip title={alt}>
      <Avatar
        className="border-0"
        sx={sx}
        style={style}
        alt={alt}
        src={avatarBinary && `data:image/jpeg;base64,${avatarBinary}`}>
        {alt?.split(' ').map((name) => name.charAt(0))}
      </Avatar>
    </Tooltip>
  );
  // } else {
  //   return (
  //     // <Tooltip title={alt}>
  //     <Avatar sx={sx} style={style} alt={alt}>
  //       {alt?.split(' ').map((name) => name.charAt(0))}
  //     </Avatar>
  //     // </Tooltip>
  //   );
  // }
}

PropTypes.AvatarFromApiBinary = {
  src: PropTypes.string.isRequired,
  altText: PropTypes.string
};
