import React, { Fragment, useContext, useEffect, useState } from 'react';

import { AccountContext } from '../../services/cognito/components/Accounts';
import { useHistory } from 'react-router-dom';

//import UserService from '../../services/userService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LanguageSwitchListItem from './LanguageSwitchListtItem';

import axios from 'axios';

import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  ButtonGroup,
  List,
  ListItem,
  Tooltip,
  Divider
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';
import Loader from '../../services/loadSpinner';

import { useLazyQuery, useQuery } from '@apollo/client';
import { USER_LOAD_BY_ID } from '../../GraphQL/users/queries';
import { CLIENTS_LOAD_ALL } from '../../GraphQL/clients/queries';

import AvatarFromApiBinary from 'components/user/AvatarFromApiBinary';

// import { UserDataContext } from 'context-provider/userDataContextProvider';
import { useTranslation } from 'react-i18next';

import { userRoleLabelsAndPrivileges } from 'services/config';
//import { CLIENT_LOAD_BY_CLIENTID } from '../../GraphQL/clients/queries';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

export default function HeaderUserbox() {
  const { t, i18n } = useTranslation();

  const [jwt, setJwt] = useState();

  // let user = null;

  const axiosAuth = axios.create({
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  });

  const { getSession, logout } = useContext(AccountContext);
  // let history = useHistory();
  const [userId, setUserId] = useState('');
  const [clientId, setClientId] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [user, setUser] = useState(null);

  // const { userRole } = useContext(UserDataContext);

  useEffect(() => {
    getSession()
      .then((data) => {
        setJwt(data.idToken.jwtToken);
        setGivenName(data['custom:givenName']);
        setFamilyName(data['custom:familyName']);
        setUserId(data['custom:userId']);
        setClientId(data['custom:clientId']);
        getUser().then((result) => {
          // console.log('Result: ', result);
          setUser(result.data.user);
        });
      })
      .catch((error) => {
        console.log('WARNING: User not logged in.');
      });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const { currentUser } = useSelector((state) => state.client);

  const { enqueueSnackbar } = useSnackbar();

  const [getUser, { loading: usersLoading, error: usersError, data: users }] =
    useLazyQuery(USER_LOAD_BY_ID, {
      variables: { id: userId }
    });

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData
  } = useQuery(CLIENTS_LOAD_ALL);

  const onApolloError = (error) => {
    enqueueSnackbar('Encountered a problem while loading data.', {
      variant: 'warning'
    });
    console.error(error);
  };

  if (usersError) onApolloError(usersError);
  //if (clientsError) onApolloError(clientsError);

  if (
    //clients === undefined ||
    users === undefined ||
    //clientsLoading ||
    usersLoading
  ) {
    return <div>Loading...</div>;
  }

  //user = users?.user;
  //const client = clients.clients[0];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Set user name here - from AWS Cognito Data
  const userName = user?.firstName + ' ' + user?.lastName;

  let userInitials = 'U';
  if (user)
    userInitials =
      user?.firstName.substring(0, 1) + user?.lastName.substring(0, 1);
  const userAvatar = user?.avatar;

  const readyAssociatedClients = user?.associatedClients.filter(
    (acl) => acl.status !== 'pending'
  );

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          {/*<StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            variant="dot">*/}
          {userAvatar ? (
            <AvatarFromApiBinary src={userAvatar} alt={userName} />
          ) : (
            <div className="avatar-icon-wrapper avatar-initials avatar-icon-xs">
              <div className="avatar-icon text-white bg-warning">
                {userInitials}
              </div>
            </div>
          )}
          {/* </StyledBadge> */}
        </Box>

        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">{userName}</div>
          {readyAssociatedClients?.length === 1 && (
            <div className="text-white-50">
              {t(
                userRoleLabelsAndPrivileges[readyAssociatedClients[0]?.userRole]
                  ?.label
              )}
            </div>
          )}
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot">
                <AvatarFromApiBinary src={userAvatar} alt={userName} />
              </StyledBadge>
            </Box>
            <div className="">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {userName}
              </div>
            </div>
            {readyAssociatedClients?.length > 1 && (
              <Divider className="w-100 my-2" />
            )}
            {readyAssociatedClients?.map((acl, index) => {
              return (
                <div
                  key={acl.clientId}
                  className="w-100 d-flex flex-column align-items-center">
                  {readyAssociatedClients.length > 1 && (
                    <>
                      <div className="text-primary font-weight-bold">
                        {clientData?.clients.find(
                          (client) => client._id == acl.clientId
                        )?.controllerLegalName || 'not found'}
                      </div>
                      <div className="text-dark text-center">
                        {t(userRoleLabelsAndPrivileges[acl?.userRole]?.label)}
                      </div>
                    </>
                  )}

                  {readyAssociatedClients.length > 1 &&
                    index < readyAssociatedClients.length - 1 && (
                      <Divider className="w-100 my-2" />
                    )}
                </div>
              );
            })}

            <Divider className="w-100 my-2" />
            {/* <ListItem button> */}
            <LanguageSwitchListItem />
            {/* </ListItem> */}

            <ListItem button component="a" href="/settings/user">
              {t('navigation_menu.my_account')}
            </ListItem>

            <ListItem button component="a" href="/logout">
              {t('common.logout')}
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
