import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
//import Login from 'aws/cognito/components/Login';
import { Redirect } from 'react-router-dom';
import { AccountContext } from '../services/cognito/components/Accounts';

const ForwardToLoginIfNotAuthenticated = ({ children }) => {
  const { getSession } = useContext(AccountContext);
  const [page, setPage] = useState(null);
  let history = useHistory();
  const ac = new AbortController();

  useEffect(() => {
    // if session cookies are gone, forward to login
    if (!window.sessionStorage.getItem('clientId')) history.push('/login');

    getSession()
      .then((session) => {
        console.log('Logged in.');
        setPage(children);
        ac.abort();
      })
      .catch((err) => {
        console.log('Not logged in.\n Forwarding to login page.');
        history.push('/login');
        ac.abort();
      });
    ac.abort();
  }, [getSession]);

  return page;
};

export default ForwardToLoginIfNotAuthenticated;
