import { gql } from '@apollo/client';

// #######################################################################################################################
// ########   LOAD                                                                                                ########
// #######################################################################################################################

export const REQUESTS_LOAD_ALL = gql`
  query RequestsLoad {
    requests {
      _id
      archived
      assignees
      creationDate
      requestSentDate
      processingStatus
      type
      endpointId
      endpointReference
      processingStartedDate
      processingFinalizedDate
      prolongation
      products
      response
      origin
      key
      keyTest
      payload {
        _id
        name
        url
      }
      events {
        _id
        type
        text
        date
        userId
        previousProcessingStatus
        newProcessingStatus
      }
      identificationParameters {
        identificationParameterId
        condition
        value
      }

      sendConfirmationEmail
      establishSecureCommsChannel

      identificationMethod
      identificationApproved
      identificationNotes
      identificationReference
      identificationRequestSentDate
      identificationValuesReceivedDate
      identificationOneTimePassword

      messages {
        _id
        message
        date
        author
        type
        visibleForRequestor
        format
        encrypted
      }
      externalRequestId
      requestorEmail
      clientId
    }
  }
`;

export const REQUESTS_LOAD_BY_CLIENTID = gql`
  query RequestsLoad($clientId: String!) {
    requests(query: { clientId: $clientId }) {
      _id
      archived
      assignees
      creationDate
      requestSentDate
      processingStatus
      type
      endpointId
      endpointReference
      processingStartedDate
      processingFinalizedDate
      products
      prolongation
      response
      origin
      key
      keyTest
      payload {
        _id
        name
        url
      }
      events {
        _id
        type
        text
        date
        userId
        previousProcessingStatus
        newProcessingStatus
      }
      identificationParameters {
        identificationParameterId
        condition
        value
      }

      sendConfirmationEmail
      establishSecureCommsChannel

      identificationMethod
      identificationApproved
      identificationNotes
      identificationReference
      identificationRequestSentDate
      identificationValuesReceivedDate
      identificationOneTimePassword

      messages {
        _id
        message
        date
        author
        type
        visibleForRequestor
        format
        encrypted
      }
      externalRequestId
      requestorEmail
      clientId
    }
  }
`;

export const REQUESTS_LOAD_BY_ASSIGNEEID = gql`
  query RequestsLoad($userId: [String!]) {
    requests(query: { assignees_in: $userId }) {
      _id
      archived
      assignees
      creationDate
      requestSentDate
      processingStatus
      type
      endpointId
      endpointReference
      processingStartedDate
      processingFinalizedDate
      products
      prolongation
      response
      key
      keyTest
      payload {
        _id
        name
        url
      }
      events {
        _id
        type
        text
        date
        userId
        previousProcessingStatus
        newProcessingStatus
      }
      identificationParameters {
        identificationParameterId
        condition
        value
      }

      sendConfirmationEmail
      establishSecureCommsChannel

      identificationMethod
      identificationApproved
      identificationNotes
      identificationReference
      identificationRequestSentDate
      identificationValuesReceivedDate
      identificationOneTimePassword

      messages {
        _id
        message
        date
        author
        type
        visibleForRequestor
        format
        encrypted
      }
      externalRequestId
      requestorEmail
      clientId
    }
  }
`;

export const REQUESTS_LOAD_BY_PROCESSINGSTATUS = gql`
  query RequestsLoadByClientIdAndProcessingStatus($clientId: String!) {
    requests(query: { clientId: $clientId }) {
      _id
      archived
      assignees
      creationDate
      requestSentDate
      processingStatus
      type
      endpointId
      endpointReference
      processingStartedDate
      processingFinalizedDate
      prolongation
      products
      response
      key
      keyTest
      origin
      payload {
        _id
        name
        url
      }
      events {
        _id
        type
        text
        date
        userId
        previousProcessingStatus
        newProcessingStatus
      }
      identificationParameters {
        identificationParameterId
        condition
        value
      }

      sendConfirmationEmail
      establishSecureCommsChannel

      identificationMethod
      identificationApproved
      identificationNotes
      identificationReference
      identificationRequestSentDate
      identificationValuesReceivedDate
      identificationOneTimePassword

      messages {
        _id
        message
        date
        author
        type
        visibleForRequestor
        format
        encrypted
      }
      externalRequestId
      requestorEmail
      clientId
    }
  }
`;

export const REQUEST_LOAD_BY_ID = gql`
  query RequestsLoadById($id: ObjectId!) {
    requests(query: { _id: $id }) {
      _id
      archived
      assignees
      creationDate
      requestSentDate
      processingStatus
      type
      endpointId
      endpointReference
      processingStartedDate
      processingFinalizedDate
      prolongation
      products
      response
      key
      keyTest
      origin
      payload {
        _id
        name
        url
      }
      events {
        _id
        type
        text
        date
        userId
        previousProcessingStatus
        newProcessingStatus
      }
      identificationParameters {
        identificationParameterId
        condition
        value
      }

      sendConfirmationEmail
      establishSecureCommsChannel

      identificationMethod
      identificationApproved
      identificationNotes
      identificationReference
      identificationRequestSentDate
      identificationValuesReceivedDate
      identificationOneTimePassword

      messages {
        _id
        message
        date
        author
        type
        visibleForRequestor
        format
        encrypted
      }
      externalRequestId
      requestorEmail
      clientId
    }
  }
`;

export const REQUEST_COUNT = gql`
  query aggregationCountRequestsForClient(
    $data: AggregationCountRequestsForClientInput!
  ) {
    aggregationCountRequestsForClient(input: $data) {
      count
    }
  }
`;
