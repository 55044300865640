import React, { Fragment, useContext } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogoNewSaffron from '../../assets/images/privapi-logos/p-logo-deep-saffron_invert_mm.svg';

// import { UserDataContext } from 'context-provider/userDataContextProvider';

const HeaderLogo = (props) => {
  // const { headerLogoUrl, headerTextColor, headerName } =
  //   useContext(UserDataContext);

  const headerTextColor = window.sessionStorage.getItem('headerTextColor');
  const headerLogoUrl = window.sessionStorage.getItem('headerLogoUrl');
  const headerName = window.sessionStorage.getItem('headerName');

  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box className="header-logo-wrapper" title="PRIVAPI">
          <Link to="/dashboard" className="header-logo-wrapper-link">
            <IconButton color="primary" size="medium">
              <img
                className="app-header-logo-img"
                alt={headerName || 'PRIVAPI'}
                src={headerLogoUrl || projectLogoNewSaffron}
              />
            </IconButton>
          </Link>

          <Box className="header-logo-text" style={{ color: headerTextColor }}>
            {headerName || 'PRIVAPI'}
          </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
