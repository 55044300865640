import { ThemeProvider } from '@material-ui/core';
import React, { createContext, useState, useMemo } from 'react';

import { createTheme } from '@material-ui/core/styles';
import typography from './typography';
// eslint-disable-next-line import/no-webpack-loader-syntax
import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

// import MuiTheme from 'theme';

export const ThemeColorContext = createContext({
  //   primaryColor,
  //   secondaryColor,
  setPrimaryColor: (color) => {},
  setSecondaryColor: (color) => {}
});

export default function ThemeWrapper(props) {
  const [primaryColor, setPrimaryColor] = useState(
    window.sessionStorage.getItem('primaryColor')
  );
  const [secondaryColor, setSecondaryColor] = useState(
    window.sessionStorage.getItem('secondaryColor')
  );

  const MuiTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor || vars.primary
        // main: vars.primary
      },
      grey: {
        300: vars.inheritDefault1,
        A100: vars.inheritDefault2
      },
      secondary: {
        main: secondaryColor || vars.secondary
        // main: vars.secondary
      },
      error: {
        main: vars.red
      },
      success: {
        main: vars.green
      },
      warning: {
        main: vars.orange
      },
      helpers: {
        primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)'
      },
      contrastThreshold: 3,
      tonalOffset: 0.1
    },
    shape: {
      borderRadius: '0.5rem'
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: vars.second
        }
      }
    },
    typography
  });

  const theme = useMemo(() => MuiTheme, [primaryColor, secondaryColor]);

  // const theme = MuiTheme;

  function changePrimaryColor(color) {
    console.log('Setting primary color to: ' + color);
    if (color && color !== 'undefined') {
      window.sessionStorage.setItem('primaryColor', color);
      setPrimaryColor(color);
    }
  }

  function changeSecondaryColor(color) {
    console.log('Setting secondary color to: ' + color);
    if (color && color !== 'undefined') {
      window.sessionStorage.setItem('secondaryColor', color);
      setSecondaryColor(color);
    }
  }

  return (
    <ThemeColorContext.Provider
      value={{
        setPrimaryColor: changePrimaryColor,
        setSecondaryColor: changeSecondaryColor
      }}
      //   key={changeCounter}
    >
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeColorContext.Provider>
  );
}
