import React, { createContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Pool from '../UserPool';

const AccountContext = createContext();

const Account = (props) => {
  const [userId, setUserId] = useState();
  const [clientId, setClientId] = useState(undefined);
  const [userGivenName, setUserGivenName] = useState(undefined);
  const [userFamilyName, setUserFamilyName] = useState(undefined);
  const [userGroups, setUserGroups] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);
  const [idToken, setIdToken] = useState(undefined);

  const setUserDataEmpty = () => {
    setUserId(null);
    setClientId(null);
    setUserGivenName(null);
    setUserFamilyName(null);
    setUserGroups(null);
    setAccessToken(null);
    setIdToken(null);
  };

  const setUserData = (session) => {
    console.log('setUserData with:', session);
    if (session) {
      setUserId(session?.idToken?.payload['custom:userId']);
      setClientId(session?.idToken?.payload['custom:clientId']);
      setUserGivenName(session.idToken?.payload['custom:givenName']);
      setUserFamilyName(session.idToken?.payload['custom:familyName']);
      setUserGroups(session?.idToken?.payload['cognito:groups']);
      setAccessToken(session?.accessToken?.jwtToken);
      setIdToken(session?.idToken?.jwtToken);
    }
  };

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            // setUserData(session);

            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  console.log('Error getting user attributes: ', err);
                  forwardToLogin();
                  reject(err); // not useful here after redirecting... I know... ;)
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({
              user,
              ...session,
              ...attributes
            });
          }
        });
      } else {
        console.log('User is not logged in.');
        // remove encrypted password hash from session storage (used to decrypt client's private key to access encrypted communication)
        window.sessionStorage.removeItem('hash');
        // setUserDataEmpty();
        reject();
      }
    });

  const forwardToLogin = () => {
    window.sessionStorage.removeItem('hash');
    window.location.href = '/login';
  };

  const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          data.authChallenge = 'none';
          //console.log('onSuccess:', data);
          // userGroups = data.idToken.payload['cognito:groups'];

          // feed user session data to context variables
          setUserData(data);

          resolve(data);
        },

        onFailure: (err) => {
          setUserDataEmpty();

          console.error('onFailure:', err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          //console.log('newPasswordRequired:', data);
          data.authChallenge = 'newPasswordRequired';
          resolve(data);
        }
      });
    });

  const completeNewPasswordChallenge = (Username, Password, newPassword) =>
    new Promise((resolve, reject) => {
      console.log('Username', Username);
      console.log('Old Password', Password);
      console.log('New Password', newPassword);
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          data.authChallenge = 'none';
          setUserData(data);
          //console.log('onSuccess:', data);
          resolve(data);
        },

        onFailure: (err) => {
          console.error('onFailure:', err);
          setUserDataEmpty();
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log('newPasswordRequired:', data);

          user.completeNewPasswordChallenge(newPassword, [], {
            onSuccess: (session) => {
              // login
              console.log('Password changed succesfully.', session);
              setUserData(session);
              resolve(session);
            },
            onFailure: (err) => {
              console.error('Failed changing password:', err);
              reject(err);
            }
          });

          // resolve(data);
        }
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.signOut();
    }
  };

  const getIdToken = async () =>
    new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(session.idToken.jwt);
          }
        });
      }
    });

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,

        completeNewPasswordChallenge,
        userId,
        clientId,
        userFamilyName,
        userGivenName,
        userGroups,
        accessToken,
        idToken,
        getIdToken,
        logout
      }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
