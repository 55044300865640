import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Paper, List, ListItem, ListItemText } from '@material-ui/core';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

const Footer = (props) => {
  const { t, i18n } = useTranslation();

  const { footerShadow, sidebarToggle, footerFixed } = props;
  return (
    <Fragment>
      <Paper
        square
        elevation={footerShadow ? 11 : 2}
        className={clsx('app-footer text-black-50', {
          'app-footer--fixed': footerFixed,
          'app-footer--fixed__collapsed': sidebarToggle
        })}>
        <div className="app-footer--inner">
          <div className="app-footer--first">
            {/* <List dense className="d-flex align-items-center">
              <ListItem
                className="rounded-sm text-nowrap"
                button
                component={Link}
                to="https://www.privapi.io/impressum">
                <ListItemText primary={t('common.imprint')} />
              </ListItem>
              <ListItem
                className="rounded-sm text-nowrap"
                button
                component={Link}
                to="https://www.privapi.io/datenschutz">
                <ListItemText primary={t('common.privacy_policy')} />
              </ListItem>
              {/* <ListItem
                className="rounded-sm text-nowrap"
                button
                component={Link}
                to="/contact">
                <ListItemText primary={t('common.contact')} />
              </ListItem> 
            </List> */}
          </div>
          <div className="app-footer--second">
            <a href="https://www.privapi.io" title="PRIVAPI GmbH">
              PRIVAPI GmbH
            </a>
            &nbsp;&#124;&nbsp;
            <a href="https://www.privapi.io/impressum">Impressum</a>
            &nbsp;&#124;&nbsp;
            <a href="https://www.privapi.io/datenschutz">Datenschutz</a>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});
export default connect(mapStateToProps)(Footer);
