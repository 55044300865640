import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { Suspense } from 'react';
import { PulseLoader } from 'react-spinners';

// import i18n (needs to be bundled ;))
import './i18n';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.REACT_APP_ENV === 'PROD') {
  // disable React Dev Tools for production
  disableReactDevTools();

  // deactivate  console logging on PROD
  console.log = () => {};
}

if (process.env.REACT_APP_ENV !== 'LOCAL') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}
const SuspenseLoading = () => {
  return (
    <>
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <PulseLoader color={'#5383ff'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Loading...
        </div>
      </div>
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<SuspenseLoading />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
